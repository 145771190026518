/* @import url('https://bimape-bucket-2.s3.us-east-2.amazonaws.com/PlusJakartaSans-Regular.ttf'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Jakarta Font */
/* @font-face {
	font-family: 'Jakarta';
	src: url('./assets/fonts/PlusJakartaSans-Medium.ttf');
} */

* {
	font-family: 'Inter', sans-serif;
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}
p {
	padding: 0;
}

body {
	background-color: #f5f5f5;
	height: 100%;
}
html,
#root {
	height: 100%;
}
a {
	text-decoration: none;
}

select {
	font-family: Inter !important;
}

.tippy-popper {
	pointer-events: auto !important;
}

/* to prevent blurry content in tooltips */
.tippy-popper,
.tippy-tooltip,
.tippy-tooltip-content {
	-webkit-font-smoothing: antialiased !important;
}

.tippy-tooltip {
	padding: 0rem 0.1rem !important;
}

.flex-container {
	display: flex;
	align-items: center;
}
.full-flex-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* image preview modal */
#image_preview {
	max-width: 70vw !important;
}

*::-webkit-input-placeholder {
	color: #8d8d8d;
}
*:-moz-placeholder {
	/* FF 4-18 */
	color: #8d8d8d;
	opacity: 1;
}
*::-moz-placeholder {
	/* FF 19+ */
	color: #8d8d8d;
	opacity: 1;
}
*:-ms-input-placeholder {
	/* IE 10+ */
	color: #8d8d8d;
}
*::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #8d8d8d;
}
*::placeholder {
	/* modern browser */
	color: #8d8d8d;
}

.highlight-container {
	box-sizing: border-box;
	outline: solid #57ba70;
	outline-width: 1px;
	animation: flashBorder 2s forwards;
	transition: outline-width 2s ease-in-out;
}

@keyframes flashBorder {
	0% {
		outline-width: 1px;
	}
	25% {
		outline-width: 4px;
	}
	50% {
		outline-width: 1px;
	}
	75% {
		outline-width: 4px;
	}
	100% {
		outline-width: 1px;
	}
}
